import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table';
import Button from '../../Components/UI-Elements/Button';
import DataExport from '../../Components/UI-Elements/DataExport';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import {useParametersQuery, useCustomersQuery, useSettingsQuery, parameterPutRequest} from '../../Components/Hooks/QueryFunctions';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';

import '../../Styles/Pages/ListPage.css'
import EditParameter from './EditParameter';
import { useAuth } from '../../Components/Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { generateOptions } from '../../Common/Helper';

function ListParameter() {
  let auth = useAuth();
  const queryClient = useQueryClient()

  const modalOpen = useRef(null)
  const modalClose = useRef(null)

  const [modalData, setModalData] = useState(null)

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const [tableData, setTableData] = useState(null)

  const [customerFilter, setCustomerFilter] = useState(null)

  const [mappedSelected, setMappedSelected] = useState(false)
  const [excludeRawSelected, setExcludeRawSelected] = useState(true)
  const [assignedNameSelected, setAssignedNameSelected] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)

  const [customFileName, setCustomFileName] = useState(null);

  const paramQuery = useParametersQuery({
    mappedSelected: mappedSelected,
    excludeRawSelected: excludeRawSelected,
    assignedNameSelected: assignedNameSelected
  });

  const customersQuery = useCustomersQuery();
  const settingsQuery = useSettingsQuery();

  const mutation = useMutation({
    mutationFn: (requestDetails) => parameterPutRequest(auth, requestDetails),
    onSuccess: (putData) => {
      handleModalClose()
      openPopup({ type: 'success', text: 'Parameter Updated Successfully' })
      const urlParams = {
        mappedSelected: mappedSelected,
        excludeRawSelected: excludeRawSelected,
        assignedNameSelected: assignedNameSelected
      }
      const updatedData = updateData(putData, urlParams)
      queryClient.setQueryData(['get_parameters', urlParams], updatedData)
      setTableData(updatedData)
    },
    onError: (error) => {
      const errorMsg = error.message ? error.message : 'error';
      openPopup({ type: 'error', text: errorMsg })
    }
  })

  const handleModalOpen = (index) => {
    setModalData({ ...paramQuery.data[index] })
    modalOpen.current()
  }

  const handleModalClose = (index) => {
    setModalData("")
    modalClose.current()
  }

  const updateData = (newData, urlParams) => {
    let currentData = queryClient.getQueryData(['get_parameters', { ...urlParams, detailed: true }])
    newData.forEach((param) => {
      let index = currentData.findIndex((item) => item.parameter_id === param.parameter_id)
      currentData[index] = {...currentData[index], ...param}
    })

    return currentData
  }

  const fileExportDate = () => { 
    const today = new Date(); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear(); 
   
    const formattedDate = `${day}${month}${year}`; 
    return formattedDate; 
  }

  useEffect(()=>{ 
    const customer=customersQuery.data && customersQuery.data.find((customer)=>customer.customer_id === customerFilter) 
    const filename_date=fileExportDate(); 
    if (customer) { 
      setCustomFileName(`${customer.gcs_name.split(' ').join('_')}_${filename_date}`.toLowerCase()+"_ParameterExport.csv") 
    } 
    else{ 
      setCustomFileName(`all_customers_${filename_date}`.toLowerCase()+"_ParameterExport.csv") 
    } 
  },[customerFilter])

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    if (paramQuery.data.length > 0) {
      let temp = [...paramQuery.data].map((value, index) => {
        value["actions"] =  <div style={{ display: 'flex',justifyContent:'space-around'  }}>
                              <div className='list-action' onClick={() => handleModalOpen(index)}><span className="material-icons">launch</span></div>
                            </div>
        return value
      })
  
      setTableData(temp)
    }
  }, [paramQuery.data])

  const dataframeStyle = {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    flexDirection: "column",
    flexWrap: "wrap",
    overflowY: 'visible',
    overflowX: 'visible',
  };
  const Header = [
    { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
    // { Header: 'Parameter ID', accessor: 'parameter_id' },
    { Header: 'FDA Name', accessor: 'fda_name', canFilter: true },
    { Header: 'Boeing Mnemonic Code', accessor: 'boeing_mnemonic_code', canFilter: true },
    { Header: 'Airplane', accessor: 'airplane', canFilter: true },
    { Header: 'Parameter Description', accessor: 'parameter_desc', canFilter: true },
    { Header: 'ATA Code', accessor: 'ata_code', canFilter: true },
    { Header: 'IA Sensor Name', accessor: 'ia_sensor_name', canFilter: true },
    { Header: 'Aggregators', accessor: 'aggregators', Cell: ({ cell: { value } }) => { return value ? value.toString() : value }, canFilter: true },
    { Header: 'Additional Information', accessor: 'additional_info', canFilter: true },
    { Header: 'Dataframes', accessor: 'dataframes',Cell: ({ cell: { value } }) => { return value ?
        <div style={{maxHeight: "200px", overflowY: 'scroll',overflowX: 'scroll'}}> {Object.keys(value).map((k) =>
          <div style={dataframeStyle}>{k}: {Object.keys(value[k]).map((c) =>
            <div style={{paddingLeft: '10px'}}>
              • <font color="#0400ff">{c}</font>: {value[k][c]}
            </div>)}
          </div>)}
        </div>
        : null }, canFilter: true },
    { Header: 'GCS Name', accessor: 'gcs_names_temp',Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true }
  ]

  const HeaderWithSpecifiedCustomer = [
    ...Header,
    { Header: 'Flight Phases', accessor: 'customers', Cell: ({ cell: { value } }) => { return value && value[customerFilter] ? Object.keys(value[customerFilter]).map((k) => <p>{k}: {value[customerFilter][k]}</p>) : null }, canFilter: true }
  ]

  if (customersQuery.status === 'success') {
    var CustomerOptions = customersQuery.data.map((customer, index) => {
      return (<option key={index} value={parseInt(customer.customer_id)}>{customer.gcs_name}</option>)
    })
  }

  if (paramQuery.status === 'pending' || !CustomerOptions || settingsQuery.status === 'pending') {
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
        </div>
      </>
    )
  } else if (tableData != null && settingsQuery.status === 'success') {
    return (
      <>
        <div className='list-page-subcontainer'>
          <Table
            header={customerFilter ? HeaderWithSpecifiedCustomer : Header}
            data={customerFilter ? tableData.filter((p) => customerFilter==10 ? !p.customers  : p.customers && Object.keys(p.customers).includes(customerFilter.toString())) : tableData}
            title='Parameter'
            pageSize={DEFAULT_PAGE_SIZE}
            customContent={
              <span>
                <div className='input-element'>
                  <label className='input_label'>Customer</label>
                  <select style={{ width: '250px' }} className='input_box' value={customerFilter || ''} onChange={event => setCustomerFilter(parseInt(event.target.value))} name="customer_id">
                    <option value="">Filter by Customer...</option>
                    {CustomerOptions}
                  </select>
                </div>
                <span className="checkbox-wrapper"> 
                  <Checkbox 
                    checked={mappedSelected} 
                    id="mapped_selection"
                    onChange={(event) => setMappedSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude unmapped parameters</label>
                  <Checkbox 
                    checked={excludeRawSelected} 
                    id="exclude_raw_selection"
                    onChange={(event) => setExcludeRawSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude 'RAW' prefixes</label>
                  <Checkbox 
                    checked={assignedNameSelected} 
                    id="exclude_assigned_selection"
                    onChange={(event) => setAssignedNameSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude unassigned IA names</label>
                </span>
              </span>

      
            }
          />
          <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Parameter">
            <EditParameter
              data={modalData}
              mutation={mutation}
              flightPhaseOptions={settingsQuery.data.flightPhaseOptions}
              aggregatorOptions={settingsQuery.data.aggregatorOptions}
              ataCodeOptions={settingsQuery.data.ataCodeOptions}
              customerFilter={customerFilter}
            />
          </Modal>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DataExport
              data={customerFilter ? tableData.filter((p) => customerFilter==10 ? !p.customers  : p.customers && Object.keys(p.customers).includes(customerFilter.toString())) : tableData}
              columnMapping={{
                fda_name: 'FDA Name',
                boeing_mnemonic_code: 'Boeing Mnemonic Code',
                airplane: 'Airplane',
                parameter_desc: 'Parameter Description',
                ata_code_export: 'ATA Code',
                ia_sensor_name: 'IA Sensor Name',
                aggregator_export: 'Aggregators',
                gcs_names_temp:'GCS Names',
                phase_export: 'Flight Phases',
              }}
              fileName={customFileName}
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Parameters'
            />
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={submitLoading || mutation.status === 'pending' || paramQuery.status === 'pending'} handleBackdropClose={ (event) => setSubmitLoading(false) }/>
      </>
    )
  }
}

export default ListParameter