import React, { useState, useEffect, useRef, createContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table';
import Button from '../../Components/UI-Elements/Button';
import DataExport from '../../Components/UI-Elements/DataExport';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import {useComponentsQuery, useCustomersQuery, useSettingsQuery, useAirplanesQuery, componentPutRequest} from '../../Components/Hooks/QueryFunctions';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import '../../Styles/Pages/ListPage.css'
import EditComponent from './EditComponent';

import { useAuth } from '../../Components/Auth/AuthProvider';
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { generateOptions } from '../../Common/Helper';

function ListComponent() {
  let auth = useAuth();
  const queryClient = useQueryClient();

  const modalOpen = React.useRef(null);
  const modalClose = React.useRef(null);
  const [modalData, setModalData] = useState(null);

  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [customerFilter, setCustomerFilter] = useState(null);

  const [customFileName, setCustomFileName] = useState(null);
  
  const customersQuery = useCustomersQuery();
  const settingsQuery = useSettingsQuery();
  const componentQuery = useComponentsQuery();
  const airplanesQuery = useAirplanesQuery();

  const mutation = useMutation({
    mutationFn: (requestDetails) => componentPutRequest(auth, requestDetails),
    onSuccess: (putData) => {
      handleModalClose()
      openPopup({ type: 'success', text: 'Component Updated Successfully' })
      
      const updatedData = updateData(putData)
      queryClient.setQueryData(['get_components'], updatedData)
      setTableData(updatedData)
    },
    onError: (error) => {
      const errorMsg = error.message ? error.message : 'error';
      openPopup({ type: 'error', text: errorMsg })
    }
  })

  const updateData = (newData) => {
    let currentData = queryClient.getQueryData(['get_components'])
    newData.forEach((component) => {
      let index = currentData.findIndex((item) => item.component_id === component.component_id)
      currentData[index] = {...currentData[index], ...component}
    })

    return currentData
  }

  const handleModalOpen = (index) => {
    setModalData({ ...componentQuery.data[index] })
    modalOpen.current()
  }

  const handleModalClose = (index) => {
    setModalData("")
    modalClose.current()
  }

  const fileExportDate = () => { 
    const today = new Date(); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear(); 
   
    const formattedDate = `${day}${month}${year}`; 
    return formattedDate; 
  }

  useEffect(()=>{ 
    if (customersQuery.data.length > 0){
      const customer=customersQuery.data && customersQuery.data.find((customer)=>customer.customer_id === customerFilter) 
      const filename_date=fileExportDate(); 
      if (customer) { 
        setCustomFileName(`${customer.gcs_name.split(' ').join('_')}_${filename_date}`.toLowerCase()+"_ComponentExport.csv") 
      } 
      else{ 
        setCustomFileName(`all_customers_${filename_date}`.toLowerCase()+"_ComponentExport.csv") 
      } 
    }

  },[customerFilter])

  const openPopup = (temp) => {
    setAlert(temp);
    setPopup(true);
  }

  useEffect(() => {
    if (componentQuery.data.length > 0) {
      let temp = [...componentQuery.data].map((value, index) => {
        value["actions"] = <div style={{ display: 'flex',justifyContent:'space-around'  }}>
          <div className='list-action' onClick={() => handleModalOpen(index)}><span className="material-icons">launch</span></div>
        </div>
        return value
      })

      setTableData(temp)
    }
  }, [componentQuery.data])

  const Header = [
    { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
    { Header: 'Component Name', accessor: 'component_name', canFilter: true },
    { Header: 'Part No.', accessor: 'part_no', canFilter: true },
    { Header: 'Airplane', accessor: 'airplane', canFilter: true },
    { Header: 'Component Position List', accessor: 'component_position_list', Cell: ({ cell: { value } }) => { return value.toString() }, canFilter: true },
    { Header: 'ATA Code', accessor: 'ata_code', canFilter: true },
    { Header: 'GCS Names', accessor: 'gcs_names_temp',Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true },
  ]

  const HeaderWithSpecifiedCustomer = [
    ...Header,
    { Header: 'Problems', accessor: 'customers', Cell: ({ cell: { value } }) => {  return value && value[customerFilter] ? value[customerFilter].toString() : null }, canFilter: true }
  ]

  if (customersQuery.status === 'success') {
    var CustomerOptions = customersQuery.data.map((customer, index) => {
      return (<option key={index} value={parseInt(customer.customer_id)}>{customer.gcs_name}</option>)
    })
  }

  if (componentQuery.status === 'pending' || settingsQuery.status === 'pending' || airplanesQuery.status === 'pending' || !CustomerOptions) {
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
  }
  else if (tableData != null && settingsQuery.status === 'success' && airplanesQuery.status === 'success') {
    return (
      <>
        <div className='list-page-subcontainer'>
          <Table
            header={customerFilter ? HeaderWithSpecifiedCustomer : Header}
            data={customerFilter? tableData.filter((c) => customerFilter==10 ? !c.customers : c.customers && c.customers[customerFilter]) : tableData}
            title='Component'
            pageSize={DEFAULT_PAGE_SIZE}
            customContent={
              <div className='input-element'>
                <label className='input_label'>Customer</label>
                <select style={{ width: '250px' }} className='input_box' value={customerFilter || ''} onChange={event => setCustomerFilter(parseInt(event.target.value))} name="customer_id">
                  <option value="">Filter by Customer...</option>
                  {CustomerOptions}
                </select>
              </div>
            }
          />
          <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Component">
            <EditComponent
              data={modalData}
              mutation={mutation}
              modalClose={handleModalClose}
              alert={openPopup}
              airplaneOptions={airplanesQuery.data}
              ataCodeOptions={settingsQuery.data.ataCodeOptions}
            />
          </Modal>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DataExport
              data={customerFilter? tableData.filter((c) => customerFilter==10 ? !c.customers : c.customers && c.customers[customerFilter]) : tableData}
              columnMapping={{
                component_name: 'Component Name',
                part_no: 'Part Number',
                airplane: 'Airplane',
                component_position_list: 'Component Position List',
                ata_code: 'ATA Code',
                // component_event_tag_rules: 'Event Tag Rules'
                gcs_names_temp:'GCS Names'
              }}
              fileName={customFileName}
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Components'
            />
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={componentQuery.status === 'pending'}/>
      </>
    )
  }
}

export default ListComponent