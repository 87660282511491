import React, { useState, useEffect } from 'react';
import FileUpload from '../../Components/UI-Elements/FileUpload';

import '../../Styles/Pages/AddPage.css';
import '../../Styles/UI-Elements/InputElement.css';
import Alert from '@mui/material/Alert';
import Button from '../../Components/UI-Elements/Button';
import Chip from '@mui/material/Chip';
import Divider from '../../Components/UI-Elements/Divider'
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import ParameterMapper from '../../Components/Mapping/ParameterMapper';
import { useAuth } from "../../Components/Auth/AuthProvider";
import { generateOptions } from '../../Common/Helper';
import DialogWarning from '../../Components/UI-Elements/DialogWarning';
import { Dialog } from '@mui/material';


function ProblemParameterMapping() {
    const parameterTemplate = {
        boeing_mnemonic_code: '',
        parameter_id: '',
        airplane: '',
        fda_name: '',
        parameter_desc: '',
        ata_code: [],
        ia_sensor_name: '',
        additional_info: '',
        aggregators: '',
        flight_phases: '',
    };

    const problemTemplate = {
        problem_name: '',
        customer_id: '',
        airplane_model: '',
        airplane_submodel: [],
        ata_code: [],
        version: process.env.REACT_APP_TOOL_VERSION,
    };

    const mappingTemplate = {
        problem: problemTemplate,
        parameters: [

        ],
        error: false
    };

    let auth = useAuth();
    const [parsedData, setParsedData] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [inputFields, setInputFields] = useState([mappingTemplate]);
    const [warningOpen, setWarningOpen] = useState(false);
    const [configuredParameters, setConfiguredParameters] = useState(null);
    const [configuredParametersData, setConfiguredParametersData] = useState(null);
    const [configuredProblems, setConfiguredProblems] = useState([]);
    const [configuredProblemCustomer, setConfiguredProblemCustomer] = useState([]);
    const [customerData, setCustomerData] = useState(null);
    const [airplaneData, setAirplaneData] = useState(null);

    const [flightPhaseOptions, setFlightPhaseOptions] = useState([]);
    const [aggregatorOptions, setAggregatorOptions] = useState([]);
    const [ataCodeOptions, setAtaCodeOptions] = useState([]);

    const [alert, setAlert] = useState({ type: 'error', text: '' });
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        fetchAllParameters()
        fetchCustomers()
        fetchAllProblems()
        fetchAllAirplanes()
        fetchSettings()
    }, [])

    async function fetchSettings() {
        console.log("fetching settings data...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
            .then((res) => {
                setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
                setFlightPhaseOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'Flight Phases')?.value), 'json'));
                setAggregatorOptions(generateOptions(res.data.data.find((s) => s.name === 'Aggregators')?.value?.split(','), 'string'));
            })
    }

    async function fetchAllAirplanes() {
        console.log("fectching airplane data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
            .then(res => {
                // console.log(res.data.data)
                let temp = res.data.data
                let final = {}

                temp.forEach(element => {
                    if (final[element.model]) {
                        final[element.model].push({ 'submodel': element.submodel, 'submodel_id': element.submodel_id })
                    }
                    else {
                        final[element.model] = [{ 'submodel': element.submodel, 'submodel_id': element.submodel_id }]
                    }
                });
                console.log('airplaneData', final)
                setAirplaneData(final);
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error })
            })
    }

    async function fetchAllParameters() {
        console.log("fectching all parameters...")
        await auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/parameter?detailed=true&all=true')
            .then(res => {
                console.log('parameters', res.data.data)
                let temp = res.data.data.map((elem) => {
                    return elem.fda_name + ' (' + elem.airplane + '-' + elem.boeing_mnemonic_code + ')';
                })
                setConfiguredParameters(temp)
                setConfiguredParametersData(res.data.data)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error })
            })
    }

    async function fetchAllProblems() {
        console.log("fectching all problems...")
        await auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem')
            .then(res => {
                console.log('problems', res.data.data)
                let temp = res.data.data.map((elem) => {
                    return elem.problem_name + '-' + elem.customer_id
                })
                let tempProblems = [...res.data.data];
                tempProblems.map((elem) => {
                    elem['ata_code'] = elem['ata_code'] ? elem['ata_code'].split(',') : [];
                });
                setConfiguredProblemCustomer(temp)
                setConfiguredProblems(tempProblems)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error })
            })
    }

    const fetchCustomers = async () => {
        console.log("fectching customer data...")
        await auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'customer_id': element.id,
                        'gcs_name': element.gcs_name,
                        'email': element.contact_email
                    })
                })
                console.log('customer data', data)
                setCustomerData(data)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error })
            })
    }

    function findElem(problem_name, arr) {
        // console.log('test',problem_name,arr)
        var i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i].problem.problem_name === problem_name) {
                return i; //Returns element position, so it exists
            }
        }
        return -1; //The element isn't in your array
    }

    useEffect(() => {
        if (!parsedData)
            return
        console.log(parsedData)
        var temp = []

        // Validate inputs
        const inputParams = new Set(parsedData.map(i => i['FDA Parameter Name']))
        const configuredParams = new Set(configuredParametersData.map(i => i.fda_name))
        if (inputParams.difference(configuredParams).size > 0) {
            const missingParams = Array.from(inputParams.difference(configuredParams)).join(', ')
            openPopup({ type: 'warning', text: `Please map the following before proceeding ${missingParams}` })
        }
        parsedData.forEach(item => {
            let ind = findElem(item['IA Problem'], temp)
            const parameterMatch = configuredParametersData.find((c) => c.fda_name == item['FDA Parameter Name'])

            if (ind == -1) {
                let submodels = []
                if (item['Airplane Submodel'] && !Array.isArray(item['Airplane Submodel'])) {
                    submodels = item['Airplane Submodel'].split('|')
                } else {
                    submodels = [item['Airplane Submodel']]
                }
                
                temp.push({
                    problem: {
                        problem_name: item['IA Problem'],
                        problem_id: configuredProblems.find((p) => p.problem_name == item['IA Problem']).problem_id,
                        customer_id: customerData.find((c) => c.gcs_name == item['GCS Name']).customer_id,
                        airplane_model: item['Airplane'],
                        airplane_submodel: submodels,
                        ata_code: item['Problem ATA Code'] ? item['Problem ATA Code'].split('|') : [],
                        version: process.env.REACT_APP_TOOL_VERSION,
                    },
                    parameters: [
                        {
                            airplane: item['Airplane'],
                            boeing_mnemonic_code: item['Boeing Mnemonic Code'],
                            fda_name: item['FDA Parameter Name'],
                            parameter_id: parameterMatch === undefined ? null: parameterMatch.parameter_id,
                            parameter_desc: item['Parameter Description'],
                            ata_code: item['Parameter ATA Code'] ? item['Parameter ATA Code'].split('|') : [],
                            ia_sensor_name: item['IA Base Sensor'],
                            aggregators: item['Aggregators'] ? item['Aggregators'].split('|') : [],
                            additional_info: item['Additional Information']
                        }
                    ],
                    error: false
                })
            }
            else {
                temp[ind].parameters.push({
                    airplane: item['Airplane'],
                    boeing_mnemonic_code: item['Boeing Mnemonic Code'],
                    fda_name: item['FDA Parameter Name'],
                    parameter_id: parameterMatch === undefined ? null: parameterMatch.parameter_id,
                    parameter_desc: item['Parameter Description'],
                    ata_code: item['Parameter ATA Code'] ? item['Parameter ATA Code'].split('|') : [],
                    ia_sensor_name: item['IA Base Sensor'],
                    aggregators: item['Aggregators'] ? item['Aggregators'].split('|') : [],
                    additional_info: item['Additional Information']
                })
            }
        })

        console.log('parsed data', temp)
        setInputFields(temp)
        setUploadedFile(true)
        setRefresh(!refresh)

    }, [parsedData])

    const addNewMapping = () => {
        let temp = [...inputFields];
        temp.push(mappingTemplate);
        setUploadedFile(false);
        setInputFields(temp);
    }

    const removeMapping = (index) => {
        let temp = [...inputFields];
        temp.splice(index, 1);
        setUploadedFile(false);
        setInputFields(temp);
    }

    // on click save on modal
    const saveParameterEdit = (problemIndex, parameterIndex, data) => {
        console.log('modal close', parameterIndex, data)

        let mappingList = [...inputFields];
        let temp = mappingList[problemIndex].parameters;
        temp[parameterIndex] = data;
        temp[parameterIndex]["status"] = configuredParametersData.filter((p) => p.airplane == temp[parameterIndex]['airplane'] && p.boeing_mnemonic_code == temp[parameterIndex]['boeing_mnemonic_code']).length > 0;
        temp[parameterIndex]["status_chip"] = temp[parameterIndex]["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />
        setInputFields(mappingList);
        setRefresh(!refresh);
        console.log('data after modal change', temp)
    }

    const loadConfiguredParameters = (problemId, callback) => {
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem/parameter-mappings/' + problemId)
            .then(res => {
                callback(res.data.data);
                setSubmitLoading(false)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error })
            });
    }

    // set dropdown value
    const handleProblemChange = (index, name, value, reason) => {
        let data = [...inputFields];
        let problemInputFields = data[index].problem;
        let parameterInputFields = data[index].parameters;
        problemInputFields[name] = value;
        //if an option is selected, autofill with configured problem data
        if (reason === 'reset' && value && !uploadedFile) {
            let problem = configuredProblems.find((p) => p.problem_name === value);
            if (problem) {
                setSubmitLoading(true)
                problemInputFields['problem_id'] = problem.problem_id;
                problemInputFields['customer_id'] = problem.customer_id;
                problemInputFields['airplane_model'] = problem.airplane_model;
                problemInputFields['airplane_submodel'] = problem.airplane_submodel;
                problemInputFields['ata_code'] = problem.ata_code;
                loadConfiguredParameters(problem.problem_id, (ret) => {
                    data[index].problem = problemInputFields;
                    data[index].parameters = ret;
                    data[index].parameters.map((p) => {
                        p.ata_code = p.parameter_ata_code ? p.parameter_ata_code.split(',') : [];
                    });
                    setInputFields(data);
                    setRefresh(!refresh);
                });
            }
        } else {
            if (reason === 'clear') {
                problemInputFields = problemTemplate;
                parameterInputFields = [parameterTemplate];
            }
            //if airplane model changes set sub-model to empty
            if (name === 'airplane_model')
                problemInputFields['airplane_submodel'] = [];
            data[index].problem = problemInputFields;
            data[index].parameters = parameterInputFields;
            setUploadedFile(false);
            setInputFields(data);
            setRefresh(!refresh);
        }
    }

    // on click garbage bin button
    const removeFields = (problemInd, parameterInd) => {
        console.log('delete index', parameterInd);
        let data = [...inputFields];
        let parameters = data[problemInd].parameters;
        parameters.splice(parameterInd, 1);
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }

    // add parameter to problem
    const addFields = (index) => {
        let newfield = { ...parameterTemplate };
        let data = [...inputFields];
        let parameters = data[index].parameters;
        parameters.push(newfield);
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }

    // click clear all button on a problem
    const removeAllFields = (index) => {
        let emptyParameter = { ...parameterTemplate };
        let data = [...inputFields];
        emptyParameter.status_chip = <Chip label="new" color="primary" variant="outlined" />;
        data[index].problem = { ...problemTemplate };
        data[index].parameters = [emptyParameter];
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const validateForm = () => {
        console.log("validating data...");
        let inputCopy = [...inputFields];
        inputCopy.map((inputField) => {
            let problemInputFields = inputField['problem'];
            let parameterInputFields = inputField['parameters'];
            if (problemInputFields['problem_name'] == '' || problemInputFields['customer_id'] == '' || problemInputFields['airplane_model'] == '' || problemInputFields['airplane_submodel'].length == 0) {
                inputField.error = true;
                openPopup({ type: 'error', text: 'Error!! Check Problem Fields' });
                return;
            }
            else {
                inputField.error = false;
            }

            let temp = [];
            [...parameterInputFields].forEach((element, index) => {
                if (element['fda_name'] == '' ||
                    element['airplane'] == '' ||
                    element['boeing_mnemonic_code'] == '' ||
                    element['ia_sensor_name'] == '') {
                    temp.push(index);
                }
            })
            if (temp.length > 0) {
                openPopup({ type: 'error', text: 'Error!! Check Parameter Fields' });
                inputField.error = true;
                return;
            }
        });
        if (inputCopy.find((m) => m.error == true) !== undefined)
            return true;
        return false;
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);
        setWarningOpen(false);
        console.log("submit");
        let validateError = validateForm();
        console.log('validation error : ', validateError);
        let mappings = [];
        if (!validateError) {
            inputFields.map((inputField) => {
                let problemInputFields = inputField['problem']
                let parameterInputFields = inputField['parameters']
                let airplane_id = [];
                for (var ind in airplaneData[problemInputFields['airplane_model']]) {
                    if (problemInputFields['airplane_submodel'].includes(airplaneData[problemInputFields['airplane_model']][ind]['submodel'])) {
                        airplane_id.push(airplaneData[problemInputFields['airplane_model']][ind]['submodel_id'])
                    }
                }

                mappings.push({
                    'problem': {
                        'problem_name': problemInputFields['problem_name'],
                        'problem_id': problemInputFields['problem_id'],
                        'customer_id': problemInputFields['customer_id'],
                        'ata_code': problemInputFields['ata_code'].join(','),
                        'airplane_id': airplane_id,
                        'version': problemInputFields['version'],
                    },
                    'parameters': [...parameterInputFields].map(elem => {
                        return {
                            additional_info: elem.additional_info,
                            ata_code: elem.ata_code && elem.ata_code.length ? elem.ata_code.join(',') : '',
                            airplane: elem.airplane,
                            boeing_mnemonic_code: elem.boeing_mnemonic_code,
                            fda_name: elem.fda_name,
                            parameter_id: elem.parameter_id === '' ? null : elem.parameter_id,
                            ia_sensor_name: elem.ia_sensor_name,
                            parameter_desc: elem.parameter_desc,
                            aggregators: elem.aggregators,
                            flight_phases: elem.flight_phases ? elem.flight_phases : [],
                        }
                    }),
                });
            });
            console.log('mappings', mappings)

            try {
                const res = await auth.authAxios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_API + '/problem-parameter',
                    data: {
                        "mappings": mappings,
                        "user": auth.name
                    },
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                    }
                });

                console.log('response : ', res.data.message);
                if (res.status === 200) {
                    fetchAllProblems().then(() => {
                        openPopup({ type: 'success', text: 'Success!! ' })
                        setUploadedFile(false);
                        setInputFields([mappingTemplate]);
                        setSubmitLoading(false);
                        setRefresh(!refresh);
                    })
                }
            } catch (err) {
                openPopup({ type: 'error', text: err.response ? err.response.data.error : err })
            } finally {
                setSubmitLoading(false);
            }
        } else {
            setSubmitLoading(false);
            setRefresh(!refresh);
        }
    }

    if (airplaneData && inputFields && configuredParameters && configuredProblems && customerData)
        return (
            <>
                <>
                    <div className='add-page-subcontainer'>
                        <FileUpload returnParsedData={setParsedData} />
                    </div>
                    <Divider />
                </>

                {
                    inputFields.map((input, index) => {
                        return (
                            <div key={index}>
                                <div className='add-page-subcontainer'>
                                    <ParameterMapper
                                        airplaneData={airplaneData}
                                        customerData={customerData}
                                        configuredProblemCustomer={configuredProblemCustomer}
                                        configuredProblems={configuredProblems.map(p => p.problem_name)}
                                        configuredParametersData={configuredParametersData}
                                        configuredParameters={configuredParameters}
                                        index={index}
                                        problemData={input.problem}
                                        parameterData={input.parameters}
                                        validationError={input.error}
                                        handleProblemChange={handleProblemChange}
                                        saveParameterEdit={saveParameterEdit}
                                        removeFields={removeFields}
                                        addFields={addFields}
                                        removeAllFields={removeAllFields}
                                        removeMapping={removeMapping}
                                        refresh={refresh}
                                        aggregatorOptions={aggregatorOptions}
                                        flightPhaseOptions={flightPhaseOptions}
                                        ataCodeOptions={ataCodeOptions}
                                    />
                                </div>
                                <br />
                            </div>

                        )
                    })
                }
                <div className='add-page-button-group'>
                    <div style={{ display: 'flex' }}>
                        <Button variant="outlined-primary" action={addNewMapping}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New Mapping</p>
                        </Button>
                        <Button variant="filled-primary" action={(event) => setWarningOpen(true)}>
                            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
                        </Button>
                    </div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
                <SimpleBackdrop loading={submitLoading} handleBackdropClose={ (event) => setSubmitLoading(false) }/>
                <DialogWarning
                    isOpen={warningOpen}
                    handleClose={() => setWarningOpen(false)}
                    handleSubmit={() => handleSubmit()}
                />
            </>
        )
    else
        return (
            <>
                <>
                    <div className='add-page-subcontainer'>
                        <FileUpload returnParsedData={setParsedData} />
                    </div>
                    <Divider />
                </>
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default ProblemParameterMapping