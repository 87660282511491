import React, { useState, useEffect } from 'react'
import FileUpload from '../../Components/UI-Elements/FileUpload'

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'
import Alert from '@mui/material/Alert';
import Button from '../../Components/UI-Elements/Button'
import Chip from '@mui/material/Chip';
import Divider from '../../Components/UI-Elements/Divider'
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import ComponentMapper from '../../Components/Mapping/ComponentMapper';
import {useAuth} from "../../Components/Auth/AuthProvider";
import { generateOptions } from '../../Common/Helper';
import DialogWarning from '../../Components/UI-Elements/DialogWarning';

function ProblemComponentMapping() {
    const componentTemplate = {
        component_name: '',
        component_id: '',
        airplane: '',
        part_no: '',
        component_event_tag_rules: '',
        ata_code: '',
        component_position_list: '',
    };

    const problemTemplate = {
        problem_name: '',
        customer_id: '',
        airplane_model: '',
        airplane_submodel: [],
        ata_code: [],
        version: process.env.REACT_APP_TOOL_VERSION,
    };

    const mappingTemplate = {
        problem: problemTemplate,
        components: [],
        error: false
    };

    let auth = useAuth();
    const [parsedData, setParsedData] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [warningOpen, setWarningOpen] = useState(false);
    const [inputFields, setInputFields] = useState([mappingTemplate])
    const [configuredComponents, setConfiguredComponents] = useState(null)
    const [configuredComponentsData, setConfiguredComponentsData] = useState(null)
    const [configuredProblems, setConfiguredProblems] = useState([])
    const [configuredProblemCustomer, setConfiguredProblemCustomer] = useState([])
    const [customerData, setCustomerData] = useState(null)
    const [airplaneData, setAirplaneData] = useState(null)
    const [ataCodeOptions, setAtaCodeOptions] = useState([]);

    //error alert popup state
    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    useEffect(() => {
        fetchAllComponents()
        fetchCustomers()
        fetchAllProblems()
        fetchAllAirplanes()
        fetchSettings()
    }, [])

    async function fetchAllAirplanes() {
        console.log("fectching airplane data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
            .then(res => {
                // console.log(res.data.data)
                let temp = res.data.data
                let final = {}

                temp.forEach(element => {
                    if (final[element.model]) {
                        final[element.model].push({ 'submodel': element.submodel, 'submodel_id': element.submodel_id })
                    }
                    else {
                        final[element.model] = [{ 'submodel': element.submodel, 'submodel_id': element.submodel_id }]
                    }
                });
                setAirplaneData(final);
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    async function fetchAllComponents() {
        console.log("fectching all components...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/component')
            .then(res => {
                console.log('components', res.data.data)
                let temp = res.data.data.map((elem) => {
                    return elem.component_name + ' (' + elem.airplane + '-' + elem.part_no + ')'
                })
                setConfiguredComponents(temp)
                setConfiguredComponentsData(res.data.data)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    async function fetchAllProblems() {
        console.log("fectching all problems...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem')
            .then(res => {
                console.log('problems', res.data.data)
                let temp = res.data.data.map((elem) => {
                    return elem.problem_name + '-' + elem.customer_id
                })
                let tempProblems = [...res.data.data];
                tempProblems.map((elem) => {
                    elem['ata_code'] = elem['ata_code'] ? elem['ata_code'].split(',') : [];
                });
                setConfiguredProblemCustomer(temp)
                setConfiguredProblems(tempProblems)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    const fetchCustomers = () => {
        console.log("fectching customer data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'customer_id': element.id,
                        'gcs_name': element.gcs_name,
                        'email': element.contact_email
                    })
                })
                console.log('customer data', data)
                setCustomerData(data)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    async function fetchSettings() {
        console.log("fetching settings data...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
            .then((res) => {
                setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
            })
    }

    function findElem(problem_name, arr) {
        // console.log('test',problem_name,arr)
        var i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i].problem.problem_name === problem_name) {
                return i; //Returns element position, so it exists
            }
        }
        return -1; //The element isn't in your array
    }

    useEffect(() => {
        if (!parsedData)
            return
        console.log(parsedData)
        var temp = []

        parsedData.forEach(item => {
            let ind = findElem(item['IA Problem'], temp)

            if (ind == -1) {
                let submodels = []
                if (item['Airplane Submodel'] && !Array.isArray(item['Airplane Submodel'])) {
                    submodels = item['Airplane Submodel'].split('|')
                } else {
                    submodels = [item['Airplane Submodel']]
                }

                temp.push({
                    problem: {
                        problem_name: item['IA Problem'],
                        problem_id: configuredProblems.find((p) => p.problem_name == item['IA Problem']).problem_id,
                        customer_id: customerData.find((c) => c.gcs_name == item['GCS Name']).customer_id,
                        airplane_model: item['Airplane'],
                        airplane_submodel: submodels,
                        ata_code: item['Problem ATA Code'].split('|'),
                        version: process.env.REACT_APP_TOOL_VERSION,
                    },
                    components: [
                        {
                            component_name: item['Component Name'],
                            component_id: configuredComponentsData.find((c) => c.component_name == item['Component Name']).component_id,
                            airplane: item['Airplane'],
                            part_no: item['Part Number'],
                            component_event_tag_rules: item['Event Tag Rules'],
                            ata_code: item['Component ATA Code'],
                            component_position_list: item['Component Position List'],
                        }
                    ],
                    error: false
                })
            }
            else {
                temp[ind].components.push({
                    component_name: item['Component Name'],
                    component_id: configuredComponentsData.find((c) => c.component_name == item['Component Name']).component_id,
                    airplane: item['Airplane'],
                    part_no: item['Part Number'],
                    component_event_tag_rules: item['Event Tag Rules'],
                    ata_code: item['Component ATA Code'],
                    component_position_list: item['Component Position List'],
                })
            }
        })

        console.log('parsed data', temp)
        setInputFields(temp)
        setUploadedFile(true)
        setRefresh(!refresh)
    }, [parsedData])

    const addNewMapping = () => {
        let temp = [...inputFields];
        temp.push(mappingTemplate);
        setUploadedFile(false);
        setInputFields(temp);
    }

    const removeMapping = (index) => {
        let temp = [...inputFields];
        temp.splice(index, 1);
        setUploadedFile(false);
        setInputFields(temp);
    }

    const saveComponentEdit = ((problemIndex, componentIndex, data) => {
        // console.log('modal close', index, data)
        let mappingList = [...inputFields];
        let temp = mappingList[problemIndex].components;
        temp[componentIndex] = data;

        //check if the updated fda name coresponds to a already configured component
        temp[componentIndex]["status"] = configuredComponentsData.filter((c) => c.airplane == temp[componentIndex]['airplane'] && c.part_no == temp[componentIndex]['part_no']).length > 0;
        temp[componentIndex]["status_chip"] = temp[componentIndex]["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />

        setInputFields(mappingList);
        setRefresh(!refresh);
        // console.log('data after modal change', temp)
    })

    const loadConfiguredComponents = (problemId, callback) => {
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem/component-mappings/' + problemId)
        .then(res => {
            callback(res.data.data);
            setSubmitLoading(false)
        })
        .catch(function (error) {
            openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
        });
    }

    //handle problem data change
    // reason
    // reset -> click on option
    // clear -> click on clear button
    // input -> regular input
    const handleProblemChange = (index, name, value, reason) => {
        let data = [...inputFields];
        let problemInputFields = data[index].problem;
        let componentInputFields = data[index].components;
        problemInputFields[name] = value;
        //if an option is selected, autofill with configured problem data
        if (reason === 'reset' && value && !uploadedFile) {
            let problem = configuredProblems.find((p) => p.problem_name === value);
            if(problem){
                setSubmitLoading(true)
                problemInputFields['problem_id'] = problem.problem_id;
                problemInputFields['customer_id'] = problem.customer_id;
                problemInputFields['airplane_model'] = problem.airplane_model;
                problemInputFields['airplane_submodel'] = problem.airplane_submodel;
                problemInputFields['ata_code'] = problem.ata_code;
                loadConfiguredComponents(problem.problem_id, (ret) => {
                    data[index].problem = problemInputFields;
                    data[index].components = ret;
                    data[index].components.map((p) => {
                        p.ata_code = p.component_ata_code ? p.component_ata_code.split(',') : [];
                    });
                    setInputFields(data);
                    setRefresh(!refresh);
                });
            }
        } else {
            if (reason === 'clear') {
                problemInputFields = problemTemplate;
                componentInputFields = [componentTemplate];
            }
            //if airplane model changes set sub-model to empty
            if (name === 'airplane_model')
                problemInputFields['airplane_submodel'] = [];
            data[index].problem = problemInputFields;
            data[index].components = componentInputFields;
            setUploadedFile(false);
            setInputFields(data);
            setRefresh(!refresh);
        }
    }

    //remove component field
    const removeFields = (problemInd, componentInd) => {
        console.log('delete index', componentInd);
        let data = [...inputFields];
        let components = data[problemInd].components;
        components.splice(componentInd, 1)
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }

    //add component field
    const addFields = (problemInd) => {
        let newfield = {...componentTemplate};
        let data = [...inputFields];
        let components = data[problemInd].components;
        components.push(newfield);
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }

    //removes all component fields
    const removeAllFields = (index) => {
        let emptyComponent = {...componentTemplate};
        let data = [...inputFields];
        emptyComponent.status_chip = <Chip label="new" color="primary" variant="outlined" />;
        data[index].problem = {...problemTemplate};
        data[index].components = [emptyComponent];
        setUploadedFile(false);
        setInputFields(data);
        setRefresh(!refresh);
    }
    
    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    //validate problem and components fields before submit
    const validateForm = () => {
        console.log("validating data...");
        let inputCopy = [...inputFields];
        inputCopy.map((inputField) => {
            let problemInputFields = inputField['problem'];
            let componentInputFields = inputField['components'];
            if (problemInputFields['problem_name'] == '' || problemInputFields['customer_id'] == '' || problemInputFields['airplane_model'] == '' || problemInputFields['airplane_submodel'].length == 0) {
                inputField.error = true;
                openPopup({ type: 'error', text: 'Error!! Check Problem Fields' });
                return;
            }
            else {
                inputField.error = false;
            }

            let temp = [];            
            [...componentInputFields].forEach((element, index) => {
                if (
                    // element['component_name'] == ''||
                    element['airplane'] == '' ||
                    element['part_no'] == '') {
                    temp.push(index);
                }
            })
            if (temp.length > 0) {
                openPopup({ type: 'error', text: 'Error!! Check Component Fields' });
                inputField.error = true;
                return;
            }
        });
        if(inputCopy.find((m) => m.error == true) !== undefined)
            return true;
        return false;
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);
        setWarningOpen(false);
        let validateError = validateForm();
        // console.log('validation error : ', validateError)
        let mappings = [];
        if (!validateError) {
            inputFields.map((inputField) => {
                let problemInputFields = inputField['problem']
                let componentInputFields = inputField['components']
                let airplane_id = [];
                for (var ind in airplaneData[problemInputFields['airplane_model']]) {
                    if (problemInputFields['airplane_submodel'].includes(airplaneData[problemInputFields['airplane_model']][ind]['submodel'])) {
                        airplane_id.push(airplaneData[problemInputFields['airplane_model']][ind]['submodel_id'])
                    }
                }

                mappings.push({
                    'problem': {
                        'problem_name': problemInputFields['problem_name'],
                        'problem_id': problemInputFields['problem_id'],
                        'customer_id': problemInputFields['customer_id'],
                        'ata_code': problemInputFields['ata_code'].join(','),
                        'airplane_id': airplane_id,
                        'version': problemInputFields['version'],
                    },
                    'components': [...componentInputFields].map(elem => {
                        return {
                            component_name: elem.component_name,
                            component_id: elem.component_id === '' ? null : elem.component_id,
                            airplane: elem.airplane,
                            part_no: elem.part_no,
                            component_event_tag_rules: elem.component_event_tag_rules ? elem.component_event_tag_rules.replace(/^"(.+)"$/,'$1') : null,
                            ata_code: elem.ata_code == '' ? null : [elem.ata_code].join(','),
                            component_position_list: elem.component_position_list ? elem.component_position_list.toUpperCase().split(',') : '', // it's a convention to use positions in upper case,
                        }
                    }),
                });
            });

            console.log('mappings', mappings)

            try {
                const res = await auth.authAxios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_API + '/problem-component',
                    data: {
                        "mappings": mappings,
                        "user": auth.name
                    },
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                    }
                });

                console.log('response : ', res.data.message);
                if (res.status === 200) {
                    fetchAllProblems().then(() => {
                        openPopup({ type: 'success', text: 'Success!! ' })
                        setUploadedFile(false);
                        setInputFields([mappingTemplate]);
                        setSubmitLoading(false);
                        setRefresh(!refresh);
                    })
                }
            } catch (err) {
                openPopup({ type: 'error', text: err.response ? err.response.data.error : err})
            } finally {
                setSubmitLoading(false);
            }
        } else {
            setSubmitLoading(false);
            setRefresh(!refresh);
        }
    }

    if (airplaneData && inputFields && configuredComponents && configuredProblems && customerData)
        return (
            <>
                <>
                    <div className='add-page-subcontainer'>
                        <FileUpload returnParsedData={setParsedData} />
                    </div>
                    <Divider />
                </>

                {
                    inputFields.map((input, index) => {
                        return (
                            <div key={index}>
                                <div className='add-page-subcontainer'>
                                    <ComponentMapper
                                        airplaneData={airplaneData}
                                        customerData={customerData}
                                        configuredProblemCustomer={configuredProblemCustomer}
                                        configuredProblems={configuredProblems.map(p => p.problem_name)}
                                        configuredComponentsData={configuredComponentsData}
                                        configuredComponents={configuredComponents}
                                        index={index}
                                        problemData={input.problem}
                                        componentData={input.components}
                                        validationError={input.error} 
                                        handleProblemChange={handleProblemChange}
                                        saveComponentEdit={saveComponentEdit}
                                        removeFields={removeFields}
                                        addFields={addFields}
                                        removeAllFields={removeAllFields}
                                        removeMapping={removeMapping}
                                        refresh={refresh}
                                        uploadedFile={uploadedFile}
                                        ataCodeOptions={ataCodeOptions}
                                    />
                                </div>
                                <br />
                            </div>

                        )
                    })
                }
                <div className='add-page-button-group'>
                    <div style={{ display: 'flex' }}>
                        <Button variant="outlined-primary" action={addNewMapping}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New Mapping</p>
                        </Button>
                        <Button variant="filled-primary" action={() => setWarningOpen(true)}>
                            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
                        </Button>
                    </div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
                <SimpleBackdrop loading={submitLoading} handleBackdropClose={ (event) => setSubmitLoading(false) }/>
                <DialogWarning
                    isOpen={warningOpen}
                    handleClose={() => setWarningOpen(false)}
                    handleSubmit={() => handleSubmit()}
                />
            </>
        )
    else
        return (
            <>
                <>
                    <div className='add-page-subcontainer'>
                        <FileUpload returnParsedData={setParsedData} />
                    </div>
                    <Divider />
                </>
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default ProblemComponentMapping