import React, { useState, useEffect } from 'react';
import Button from '../../Components/UI-Elements/Button';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useAuth } from '../../Components/Auth/AuthProvider';

function EditComponent(props) {
  let auth = useAuth();

  const [data, setData] = useState(null);
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [airplaneModelOptions, setAirplaneModelOptions] = useState(null);

  const openPopup = (temp) => {
    setAlert(temp);
    setPopup(true);
  }

  useEffect(() => {
    let temp = {...props.data};
    temp.component_event_tag_rules = JSON.stringify(temp.component_event_tag_rules);
    temp.component_position_list = temp.component_position_list.join(',');
    setData(temp);
  }, [])


  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value ?? '';
    setData(temp);
  }

  const handleSubmit = () => {
    const validated = validateAndFormat(data)
    if (validated != null) {
      console.log('Submitting form data', data)

      props.mutation.mutate({
        putUrl: validated.putUrl, 
        putData: validated.putData
      })
    }
  }

  function validateComponentPutRequest() {
    let res = false;
    console.log('Validation component put request...')

    if(
      // data['component_name']== '' ||
      data['airplane'] == '' ||
      data['part_no'] == '')
      {
        openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
        res = true
      }

    return res;
  }

  function validateAndFormat(putData){
    let validateError = validateComponentPutRequest()
    if (validateError) {
      openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
    }
    console.log('validation error : ', validateError)

    if (!validateError) {

      let req = {
        'component_id': putData['component_id'],
        'component_name': putData['component_name'],
        'part_no': putData['part_no'],
        'airplane': putData['airplane'],
        'component_position_list': putData['component_position_list'].toUpperCase(), // it's a convention to use positions in upper case
        'ata_code': putData['ata_code'],
        'component_event_tag_rules': putData['component_event_tag_rules'].replace(/^"(.+)"$/,'$1'),
      }

      const putUrl = process.env.REACT_APP_BACKEND_API + '/component/' + putData['component_id']

      return {
        putData: req, 
        putUrl: putUrl
      }

    } else {
      return null
    }
  }

  useEffect(() => {
    if (Object.keys(props.airplaneOptions).length > 0) {
      let airplaneKeys = Object.keys(props.airplaneOptions)
  
      const modelOptions = [...airplaneKeys].map((item, index) => {
          return (<MenuItem key={index} value={item}>{item}</MenuItem>)
      })
      setAirplaneModelOptions(modelOptions)
    }
  }, [props.airplaneOptions])


  if (data != null)
    return (
      <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
              <label className='input_label'>Component Name</label>
              <input style={{ width: '250px' }} value={data.component_name} onChange={event => handleFormChange(event)} className={data.component_name == '' ? 'input_box_error' : 'input_box'} type="text" name="component_name" placeholder="Enter Component Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Part No. *</label>
              <input style={{ width: '250px' }} className={data.part_no == '' ? 'input_box_error' : 'input_box'} value={data.part_no} onChange={event => handleFormChange(event)} type="text" name="part_no" placeholder="Enter Part Number..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Airplane Model*</label>
              <Select
                name="airplane"
                className={data.airplane == '' ? 'input_box_error' : 'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Airplane Model..."
                value={data.airplane || ''}
                options={airplaneModelOptions}
                onChange={event => handleFormChange(event)}
              >
                {airplaneModelOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>Component Position List</label>
              <input style={{ width: '250px' }} value={data.component_position_list} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="component_position_list" placeholder="Enter Component Positions..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>ATA Code</label>
              <Select
                name="ata_code"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select ATA Code..."
                value={data.ata_code || []}
                options={props.ataCodeOptions}
                onChange={event => handleFormChange(event)}
              >
                {props.ataCodeOptions}
              </Select>
            </div>
            {/* <div className='input-element'>
              <label className='input_label'>Event Tag Rules</label>
              <input style={{ width: '250px' }} value={data.component_event_tag_rules} onChange={event => handleFormChange(event)} className='input_box' type="text" name="component_event_tag_rules" placeholder="Enter Event Tag Rules..."/>
            </div> */}
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={props.mutation.status === 'pending' || Object.keys(props.airplaneOptions).length === 0 || props.ataCodeOptions.length === 0}/>
      </div>
    )
  else
    return null
}

export default EditComponent