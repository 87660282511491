import React, { useState, useEffect } from 'react'

import FileUpload from '../../Components/UI-Elements/FileUpload'
import Divider from '../../Components/UI-Elements/Divider'
import Button from '../../Components/UI-Elements/Button'
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useComponentsQuery, useAirplanesQuery, useSettingsQuery, componentPostRequest} from '../../Components/Hooks/QueryFunctions';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'
import { useAuth } from '../../Components/Auth/AuthProvider'
import { generateOptions } from '../../Common/Helper';

function AddComponent() {
  let auth = useAuth();
  const queryClient = useQueryClient();

  const componentTemplate = {
    component_name: '',
    part_no: '',
    airplane: '',
    component_position_list: '',
    ata_code: '',
    component_event_tag_rules: ''
  };

  //default input field data
  const [inputFields, setInputFields] = useState([componentTemplate])

  const [submitLoading, setSubmitLoading] = useState(false)

  // State to store parsed data
  const [parsedData, setParsedData] = useState(null);
  const [airplaneModelOptions, setAirplaneModelOptions] = useState(null);
  const [ataCodeOptions, setAtaCodeOptions] = useState([]);

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const airplanesQuery = useAirplanesQuery();
  const settingsQuery = useSettingsQuery();
  const componentQuery = useComponentsQuery();
  
  const mutation = useMutation({
    mutationFn: (requestDetails) => componentPostRequest(auth, requestDetails),
    onSuccess: (postData) => {
      openPopup({ type: 'success', text: 'Component Added Successfully' });
      setInputFields([componentTemplate]);
      const updatedData = updateData(postData);
      queryClient.setQueryData(['get_components'], updatedData);
    },
    onError: (err) => {
      const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
      openPopup({ type: 'error', text: msg })
    }
  })

  const updateData = (newData) => {
    let currentData = queryClient.getQueryData(['get_components'])
    newData.forEach((component) => {
      let index = currentData.findIndex((item) => item.component_id === component.component_id)
      currentData[index] = {...currentData[index], ...component}
    })

    return currentData
  }

  const openPopup = (temp) => {
    setAlert(temp);
    setPopup(true);
  }

  useEffect(() => {
    if (airplanesQuery.status === 'success') {
      let airplaneKeys = Object.keys(airplanesQuery.data);

      const airplaneOptions = [...airplaneKeys].map((item, index) => {
          return (<MenuItem key={index} value={item}>{item}</MenuItem>)
      });
      setAirplaneModelOptions(airplaneOptions);
    }
  }, [airplanesQuery.data]);

  //set input fields to parsed data when file uploaded
  useEffect(() => {
    if (!parsedData)
      return;

    // console.log('data',parsedData)
    let temp = parsedData.map((d) => {
      return {
        component_name: d['Component Name'],
        part_no: d['Part Number'],
        airplane: d['Airplane'],
        component_position_list: d['Component Position List'].split('|'),
        ata_code: d['ATA Code'],
        component_event_tag_rules: d['Event Tag Rules']
      };
    });
    // console.log('temp', temp)
    setInputFields(temp)
  }, [parsedData])

  //handle input field change
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  }

  //add component button triggers this
  const addFields = () => {
    setInputFields([...inputFields, componentTemplate])
  }

  //delete button function
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const validatedData = validateAndFormat();
    if (validatedData != null) {
      console.log("submitted data", inputFields);
      mutation.mutate(validatedData);
    }
  }

  //submit function
  function validateAndFormat() {
    
    //validate input fields before submit
    let validateError = validateComponentPostRequest()

    if (validateError.length == 0) {

      const req = [...inputFields].map((item) => {
        const existedComponent = componentQuery.data.find((c) => c.airplane == item['airplane'] && c.part_no == item['part_no']);
        const convertToUppercase =(a)=>{
          let upperCaseList;
          if(typeof a==='string'){
            upperCaseList=[a.toUpperCase()];
          }
          else if(Array.isArray(a)){
            upperCaseList=a.map(item=>item.toUpperCase());
          }else{
            upperCaseList=[a.toUpperCase()];
          }
          return upperCaseList
        }
        return {
          'component_id': existedComponent ? existedComponent.id : undefined,
          'component_name': item['component_name'],
          'part_no': item['part_no'],
          'airplane': item['airplane'],
          'component_position_list': convertToUppercase(item['component_position_list']), // it's a convention to use positions in upper case
          'ata_code': item['ata_code'],
          'component_event_tag_rules': JSON.stringify(item['component_event_tag_rules']),
        }
      });

      // console.log('request : ', req)

      return {
        components: req,
        user: auth.name
      }

    } else {
      return null
    }
  }

  const [validationError, setValidationError] = useState([])

  //validate mandatory fields are filled
  const validateComponentPostRequest = () => {
    console.log("validating component data...")
    let temp = [];
    [...inputFields].forEach((element, index) => {
      if (
          // element['component_name'] == '' ||
          element['part_no'] == '' ||
          element['airplane'] == '') {
        temp.push(index)
        openPopup({ type: 'error', text: 'Error!! Check Input Fields Again.' })
        return;
      }
    })
    setValidationError(temp)
    console.log('validation errors : ', validationError)
    return temp
  }


  if (inputFields && componentQuery.data.length > 0 && componentQuery.data.length > 0 && settingsQuery.status === 'success')
    return (
      <>
        {
          // parsedData ? '' :
          <>
            <div className='add-page-subcontainer'>
              <FileUpload returnParsedData={setParsedData} />
            </div>
            <Divider />
          </>
        }
        <div className='add-page-subcontainer'>
          {
            inputFields.map((input, index) => {
              return (
                <div key={index} className='full-width'>
                  {componentQuery.data.map((item) => item.component_name).includes(input.component_name.trim()) ? <><Alert severity="warning">This Component - {input.component_name} is already configured.<br /> Any changes will update the existing configurations</Alert><br /></> : ''}
                  <div className='add-page-entity'>
                    <div className='add-page-form'>
                      <div className='input-element'>
                        <label className='input_label'>Component Name</label>
                        <input style={{ width: '200px' }} value={input.component_name} onChange={event => handleFormChange(index, event)} className={(validationError.length > 0 && validationError.includes(index)) && input.component_name == '' ? 'input_box_error' : 'input_box'} type="text" name="component_name" placeholder="Enter Component Name..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Part No. *</label>
                        <input style={{ width: '200px' }} className={(validationError.length > 0 && validationError.includes(index)) && input.part_no == '' ? 'input_box_error' : 'input_box'} value={input.part_no} onChange={event => handleFormChange(index, event)} type="text" name="part_no" placeholder="Enter Part Number..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Airplane Model*</label>
                        <Select
                          name="airplane"
                          className={validationError.length > 0 && input.airplane == '' ? 'input_box_error' : 'input_box'}
                          style={{ width: '220px ', height: '40px' }}
                          size='small'
                          placeholder="Select Airplane Model..."
                          value={input.airplane || ''}
                          options={airplaneModelOptions}
                          onChange={event => handleFormChange(index, event)}
                        >
                          {airplaneModelOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Component Position List</label>
                        <input style={{ width: '300px' }} value={input.component_position_list} onChange={event => handleFormChange(index, event)} className={'input_box'} type="text" name="component_position_list" placeholder="Enter Component Positions..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>ATA Code</label>
                        <Select
                          name="ata_code"
                          className={'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select ATA Code..."
                          value={input.ata_code || []}
                          options={settingsQuery.data.ataCodeOptions}
                          onChange={event => handleFormChange(index, event)}
                        >
                          {settingsQuery.data.ataCodeOptions}
                        </Select>
                      </div>
                      {/* <div className='input-element'>
                        <label className='input_label'>Event Tag Rules</label>
                        <input style={{ width: '250px' }} value={input.component_event_tag_rules} onChange={event => handleFormChange(index, event)} className='input_box' type="text" name="component_event_tag_rules" placeholder="Enter Event Tag Rules..." />
                      </div> */}
                    </div>
                    <div style={{ marginTop: '21px' }}>
                      <Button variant="outlined-danger" action={() => { removeFields(index) }}>
                        <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Delete</p>
                      </Button>
                    </div>
                  </div>
                  <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />
                </div>
              )
            })
          }

          <div className='add-page-button-group'>
            <Button variant="outlined-primary" action={addFields}>
              <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New</p>
            </Button>
            <div style={{ display: 'flex' }}>
              <Button variant="outlined-danger" action={() => setInputFields([componentTemplate])}>
                <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
              </Button>
              <Button variant="filled-primary" action={handleSubmit}>
                {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
              </Button>
            </div>
          </div>

        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={mutation.status === 'pending' || componentQuery.status === 'pending'}/>
      </>
    )
  else
    return (
      <>
        <Skeleton variant="rectangular" width='100%' height='200px' />
        <Divider />
        <Skeleton variant="rectangular" width='100%' height='200px' />
      </>
    )
}

export default AddComponent