import { useAuth } from '../Auth/AuthProvider'
import { useQuery } from '@tanstack/react-query';
import { generateOptions } from '../../Common/Helper';
import { VapingRoomsSharp } from '@mui/icons-material';

function throwEndpointException(error) {
  const endpoint = error.config.url.split('/').slice(3)
  const errorStatus = error.response != undefined? error.response.status: null
  const errorStatusText = error.response != undefined? error.response.statusText: null

  throw new Error(`Error fetching data from source\nEndpoint: ${ endpoint.join('/') }\nStatus: ${ errorStatus }\nStatus Text: ${errorStatusText}`)
}

function formatParameters(res) {
  console.log('parameters', res.data.data)
  let temp = [...res.data.data];
  temp.map((p) => {
    p.gcs_names_temp=p.gcs_names ?? 'Non Assign';
    p.ata_code_export = p.ata_code ? p.ata_code.split(',').join('|') : '';
    p.aggregator_export = p.aggregators.join('|');
    p.phase_export = p.customers ? Object.values(p.customers).map((c)=>JSON.stringify(c)):'';
  })

  return temp
}

function fetchParameters(auth, urlParams) {
  console.log("fectching all parameters...")
  const result = auth.authAxios.get(process.env.REACT_APP_BACKEND_API+`/parameter?detailed=${ urlParams.detailed ? 1: 0}&mapped=${ urlParams.mappedSelected ? 1 : 0}&exclude_raw=${ urlParams.excludeRawSelected ? 1 : 0}&assigned_name=${ urlParams.assignedNameSelected ? 1 : 0}`)
    .then((res) => formatParameters(res))
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
  return result
}

function formatCustomers(res) {
  let temp = []
  res.data.data.forEach(element => {
    temp.push({
      'customer_id': element.id,
      'gcs_name': element.gcs_name,
      'email': element.contact_email
    })
  })
  console.log('customer data', temp)
  return temp
}

async function fetchCustomers(auth) {
  console.log("fectching customer data...")
  const result = auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
    .then((res) => formatCustomers(res))
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
  return result
}

function formatSettings(res) {
  return {
    ataCodeOptions: generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'),
    flightPhaseOptions: generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'Flight Phases')?.value), 'json'),
    aggregatorOptions: generateOptions(res.data.data.find((s) => s.name === 'Aggregators')?.value?.split(','))
  }
}

async function fetchSettings(auth) {
  console.log("fetching settings data...");
  const result = auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
    .then((res) => formatSettings(res))
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
  return result
  }

function formatAirplanes(res) {
  let temp = res.data.data
  let final = {}
  temp.forEach(element => {
      if (final[element.model]) {
          final[element.model].push({ 'submodel': element.submodel, 'id': element.id })
      }
      else {
          final[element.model] = [{ 'submodel': element.submodel, 'id': element.id }]
      }
  });
  console.log('airplaneData', final)
  return final
}

function fetchAllAirplanes(auth) {
  console.log("fectching airplane data...")
  const result = auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
    .then((res) => formatAirplanes(res))
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
  
  return result
  }

function fetchComponents(auth) {
  console.log("fectching all components...")
  const result = auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/component')
    .then(res => {
      let temp = [...res.data.data];
      temp.map((p) => {
        p.gcs_names_temp= p.gcs_names ?? 'Non Assign';
      });
      return temp
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
  return result
  }

export function useParametersQuery(urlParams) {
  let auth = useAuth();
  let filledUrlParams = {
    detailed: true,
    mappedSelected: false,
    excludeRawSelected: true,
    assignedNameSelected: false,
    ...urlParams
  }
  const { status, data, error } = useQuery({
    queryKey: ['get_parameters', {...filledUrlParams}],
    queryFn: () => fetchParameters(auth, filledUrlParams)
  })

  if (status === 'error') {
    throwEndpointException(error);
  }

  return {
    status: status,
    data: data ?? [],
    error: error
  }
}

export function useCustomersQuery() {
  let auth = useAuth();

  const { status, data, error } = useQuery({
    queryKey: ['get_customers'],
    queryFn: () => fetchCustomers(auth)
  })

  if (status === 'error') {
    throwEndpointException(error);
  }

  return {
    status: status,
    data: data ?? [],
    error: error
  }
}

export function useSettingsQuery() {
  let auth = useAuth();

  const { status, data, error } = useQuery({
    queryKey: ['get_settings'],
    queryFn: () => fetchSettings(auth)
  })

  if (status === 'error') {
    throwEndpointException(error)
  }

  return {
    status: status,
    data: data ?? [],
    error: error
  }
}



export function useAirplanesQuery() {
  let auth = useAuth();
  let errorMessage = null

  const { status, data, error } = useQuery({
    queryKey: ['get_airplanes'],
    queryFn: () => fetchAllAirplanes(auth)
  })

  if (status === 'error') {
    throwEndpointException(error)
  }

  return {
    status: status,
    data: data ?? [],
    error: error
  }
}

export function useComponentsQuery() {
  let auth = useAuth();
  let errorMessage = null

  const { status, data, error } = useQuery({
    queryKey: ['get_components'],
    queryFn: () => fetchComponents(auth)
  })

  if (status === 'error') {
    throwEndpointException(error)
  }

  return {
    status: status,
    data: data ?? [],
    error: error
  }
}

export function parameterPutRequest(auth, requestDetails) {
  const formattedRequest = {
    'parameters':[requestDetails.putData],
    'user':auth.name
  }
  const putUrl = requestDetails.putUrl

  const result = auth.authAxios({
    method: 'put',
    url: putUrl,
    data: formattedRequest,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  })
  .then((res) => {
    console.log('response : ', res.data.data);
    return res.data.data
  })
  return result
}

export async function parameterPostRequest(auth, postRequestData) {
    const res = await auth.authAxios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + '/parameter',
      data: postRequestData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    })
    .then((res) => {
      console.log('response: ', res)
      return res
    });
  return res
  }

export async function componentPutRequest(auth, requestDetails) {
  const formattedRequest = {
    'components':[requestDetails.putData],
    'user':auth.name
  }
  const putUrl = requestDetails.putUrl

  const res = await auth.authAxios({
    method: 'put',
    url: putUrl,
    data: formattedRequest,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  })
  .then((res) => {
    console.log('response : ', res.data.data)
    return res.data.data
  });
return res
}

export async function componentPostRequest(auth, postRequestData) {
  const res = await auth.authAxios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + '/component',
      data: postRequestData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
  }).then((res) => {
    console.log('response: ', res.data.data)
    return res.data.data
  });
return res
}