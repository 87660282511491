import React, { useState, useEffect } from 'react'

import FileUpload from '../../Components/UI-Elements/FileUpload'
import Divider from '../../Components/UI-Elements/Divider'
import Button from '../../Components/UI-Elements/Button'
import { generateOptions } from '../../Common/Helper';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import {useParametersQuery, useAirplanesQuery, useSettingsQuery, parameterPostRequest} from '../../Components/Hooks/QueryFunctions';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'

import { useAuth } from '../../Components/Auth/AuthProvider'

function AddParameter() {
  let auth = useAuth();
  const queryClient = useQueryClient();

  const parameterTemplate = {
    parameter_desc: '',
    fda_name: '',
    airplane: '',
    boeing_mnemonic_code: '',
    ata_code: [],
    ia_sensor_name: '',
    aggregators: [],
    flight_phases: [],
    additional_info:''
  };

  const [inputFields, setInputFields] = useState([parameterTemplate])
  
  const [submitLoading, setSubmitLoading] = useState(false)
  const [parsedData, setParsedData] = useState(null);
  // const [airplaneData, setAirplaneData] = useState(null);
  const [airplaneModelOptions, setAirplaneModelOptions] = useState([]);

  const [validationError, setValidationError] = useState([])
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  const airplanesQuery = useAirplanesQuery();
  const settingsQuery = useSettingsQuery();
  const paramQuery = useParametersQuery({
    detailed: true
  });

  useEffect(() => {
    if (!parsedData)
      return;

    let temp = parsedData.map((d) => {
      return {
        parameter_desc: d['Parameter Description'],
        fda_name: d['FDA Name'],
        airplane: d['Airplane'],
        boeing_mnemonic_code: d['Boeing Mnemonic Code'],
        ata_code: d['ATA Code'] ? d['ATA Code'].split('|') : [],
        ia_sensor_name: d['IA Sensor Name'],
        aggregators: d['Aggregators'] ? d['Aggregators'].split('|') : [],
        flight_phases: d['Flight Phases'] ? d['Flight Phases'].split('|') : [],
        additional_info:''
      };
    });
    console.log('Parsed parameter data', temp)
    setInputFields(temp)
  }, [parsedData])


  useEffect(() => {
    if (airplanesQuery.status === 'success') {
      let airplaneKeys = Object.keys(airplanesQuery.data)
  
      const airplaneOptions = [...airplaneKeys].map((item, index) => {
          return (<MenuItem key={index} value={item}>{item}</MenuItem>)
      })
      setAirplaneModelOptions(airplaneOptions)
    }
  }, [airplanesQuery.data])


  const mutation = useMutation({
    mutationFn: (requestDetails) => parameterPostRequest(auth, requestDetails),
    onSuccess: (postData) => {
      openPopup({ type: 'success', text: 'Parameter Added Successfully' })
      setInputFields([parameterTemplate])
      queryClient.invalidateQueries({ queryKey: ['get_parameters'] })
      // const updatedData = updateData(putData, urlParams)
      // queryClient.setQueryData(['get_parameters', urlParams], updatedData)
      // setTableData(updatedData)
    },
    onError: (err) => {
      const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
      openPopup({ type: 'error', text: msg })
    }
  })

  const openPopup = (temp) => {
    setAlert(temp);
    setPopup(true);
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  }

  const addFields = () => {
    setInputFields([...inputFields, parameterTemplate]);
  }

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const validatedData = validateAndFormat();
    if (validatedData != null) {
      console.log("submitted data", inputFields);
      mutation.mutate(validatedData);
    }
  }

  function validateParameterPostRequest() {
    console.log("validating parameter data...")
    let res = false;
    let temp = [];
    [...inputFields].forEach((element, index) => {
      if (element['fda_name']==''||
          element['airplane']==''||
          element['boeing_mnemonic_code']==''||
          element['ia_sensor_name']=='') {
        temp.push(index)
        openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
        res = true;
      }
    });
    setValidationError(temp)
    console.log('validation errors : ', validationError)
    return res
  }

  function validateAndFormat() {
    console.log("submit")
    let validateError = validateParameterPostRequest()

    if (!validateError) {

      let temp = [...inputFields]

      console.log("no error")

      const req = [...temp].map((item) => {
        return {
          'parameter_desc': item['parameter_desc'],
          'airplane': item['airplane'],
          'boeing_mnemonic_code': item['boeing_mnemonic_code'],
          'fda_name': item['fda_name'].toUpperCase(),
          'ata_code': item['ata_code'].join(','),
          'ia_sensor_name': item['ia_sensor_name'].toUpperCase(),
          'additional_info': item['additional_info'] === '' ? null : item['additional_info'],
          'aggregators':item['aggregators'],
          'flight_phases':item['flight_phases']
        }
      })

      return {
        parameters: req,
        user: auth.name
      }
    }
    else {
      return null
    }
  }

  if (inputFields && paramQuery.data.length > 0 && settingsQuery.status === 'success')
    return (
      <>
        {
          <>
            <div className='add-page-subcontainer'>
              <FileUpload returnParsedData={setParsedData} />
            </div>
            <Divider />
          </>
        }
        <div className='add-page-subcontainer'>
          {
            inputFields.map((input, index) => {
              return (
                <div key={index} className='full-width'>
                  {paramQuery.data.includes(input.fda_name)?<><Alert severity="warning">This Parameter - {input.fda_name}  is already configured.<br/> Any changes will update the existing configurations</Alert><br/></>:''}
                  <div className='add-page-entity'>
                    <div className='add-page-form'>
                      <div className='input-element'>
                        <label className='input_label'>FDA Name*</label>
                        <input style={{ width: '250px' }} className={(validationError.length > 0 && validationError.includes(index)) && input.fda_name == '' ? 'input_box_error' : 'input_box'} value={input.fda_name} onChange={event => handleFormChange(index, event)} type="text" name="fda_name" placeholder="Enter FDA Name..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Boeing Mnemonic Code*</label>
                        <input style={{ width: '250px' }} className='input_box' value={input.boeing_mnemonic_code} onChange={event => handleFormChange(index, event)} type="text" name="boeing_mnemonic_code" placeholder="Enter Boeing Mnemonic Code..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Parameter Description</label>
                        <input style={{ width: '250px' }} value={input.parameter_desc} onChange={event => handleFormChange(index, event)} className='input_box' type="text" name="parameter_desc" placeholder="Enter Parameter Description..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Airplane Model*</label>
                        <Select
                          name="airplane"
                          className={validationError.length > 0 && input.airplane == '' ? 'input_box_error' : 'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select Airplane Model..."
                          value={input.airplane || ''}
                          options={airplaneModelOptions}
                          onChange={event => handleFormChange(index, event)}
                        >
                          {airplaneModelOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>ATA Code</label>
                        <Select
                          name="ata_code"
                          className={'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select ATA Code..."
                          value={input.ata_code || []}
                          options={settingsQuery.data.ataCodeOptions}
                          onChange={event => handleFormChange(index, event)}
                          multiple
                        >
                          {settingsQuery.data.ataCodeOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>IA Sensor Name*</label>
                        <input style={{ width: '250px' }} value={input.ia_sensor_name} onChange={event => handleFormChange(index, event)} className={(validationError.length > 0 && validationError.includes(index)) && input.ia_sensor_name === '' ? 'input_box_error' : 'input_box'} type="text" name="ia_sensor_name" placeholder="Enter IA Sensor Name..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Aggregators</label>
                        <Select
                          name="aggregators"
                          className={'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select Aggregators..."
                          value={input.aggregators || []}
                          options={settingsQuery.data.aggregatorOptions}
                          onChange={(e) => handleFormChange(index, e)}
                          multiple
                        >
                          {settingsQuery.data.aggregatorOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Additional Information</label>
                        <input style={{ width: '500px' }} value={input.additional_info} onChange={event => handleFormChange(index, event)} className='input_box' type="text" name="additional_info" placeholder="Enter Additional Information..." />
                      </div>
                    </div>
                    <div style={{ marginTop: '21px' }}>
                      <Button variant="outlined-danger" action={() => { removeFields(index) }}>
                        <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Delete</p>
                      </Button>
                    </div>
                  </div>
                  <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />
                </div>
              )
            })
          }

          <div className='add-page-button-group'>
            <Button variant="outlined-primary" action={addFields}>
              <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New</p>
            </Button>
            <div style={{ display: 'flex' }}>
              <Button variant="outlined-danger" action={ () => setInputFields([parameterTemplate]) }>
                <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
              </Button>
              <Button variant="filled-primary" action={handleSubmit}>
                {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
              </Button>
            </div>
          </div>

        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={mutation.status === 'pending' || paramQuery.status === 'pending'}/>
      </>
    )
  else
    return (
      <>
        <Skeleton variant="rectangular" width='100%' height='200px' />
        <Divider />
        <Skeleton variant="rectangular" width='100%' height='200px' />
      </>
    )
}

export default AddParameter