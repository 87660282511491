import React, { useState, useEffect } from 'react'
import Button from '../../Components/UI-Elements/Button'
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import { useAirplanesQuery } from '../../Components/Hooks/QueryFunctions'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../Components/Auth/AuthProvider'

function EditParameter(props) {
  let auth = useAuth();

  const [data, setData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [airplaneModelOptions, setAirplaneModelOptions] = useState([]);
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  const airplanesQuery = useAirplanesQuery();

  useEffect(() => {
    let temp = {...props.data};
    temp.ata_code = temp.ata_code ? temp.ata_code.split(','): [];
    temp.flight_phases = props.customerFilter && temp.customers[props.customerFilter] ? Object.keys(temp.customers[props.customerFilter]) : [];
    setData(temp);
  }, [])

  useEffect(() => {
    if (airplanesQuery.status === 'success') {
      let airplaneKeys = Object.keys(airplanesQuery.data)
  
      const airplaneModelOptions = [...airplaneKeys].map((item, index) => {
          return (<MenuItem key={index} value={item}>{item}</MenuItem>)
      })
      setAirplaneModelOptions(airplaneModelOptions)
    }
  }, [airplanesQuery.data])

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value;
    setData(temp);
  }

  const handleSubmit = () => {
    const validated = validateAndFormat(data)
    if (validated != null) {
      console.log('Submitting form data', data)

      props.mutation.mutate({
        putUrl: validated.putUrl, 
        putData: validated.putData
      })
    }
  }

  function validateParameterPutRequest(putData) {
    let res = false;
    console.log("validating data...");
  
    if (putData['fda_name'] == '' || 
      putData['airplane'] == '' ||
      putData['boeing_mnemonic_code'] == '' ||
      putData['ia_sensor_name'] == '') {
      res = true
    }
  
    return res;
  }

  function validateAndFormat(putData) {
    let validateError = validateParameterPutRequest(putData)
    if (validateError) {
      openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
    }
    console.log('validation error : ', validateError)
    
    if (!validateError) {
  
      let req = {
        'parameter_id': putData['parameter_id'],
        'parameter_desc': putData['parameter_desc'],
        'boeing_mnemonic_code': putData['boeing_mnemonic_code'],
        'airplane': putData['airplane'],
        'fda_name': putData['fda_name'].toUpperCase(),
        'ata_code': putData['ata_code'].join(','),
        'ia_sensor_name': putData['ia_sensor_name'].toUpperCase(),
        'additional_info':putData['additional_info'] === '' ? null : putData['additional_info'],
        'aggregators':putData['aggregators'],
      }
  
      if (props.customerFilter) {
        req['flight_phases'] = putData.flight_phases;
      }
  
      console.log('request : ', req)
      const putUrl = props.customerFilter ?
        process.env.REACT_APP_BACKEND_API + '/parameter/' + putData['parameter_id'] + '/' + props.customerFilter :
        process.env.REACT_APP_BACKEND_API + '/parameter/' + putData['parameter_id'];

      return {
        putData: req, 
        putUrl: putUrl
      }
    }
    else {
      return null
    }
  }


  if (data)
    return (
      <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
              <label className='input_label'>FDA Name *</label>
              <input style={{ width: '250px' }} className={data.fda_name == '' ? 'input_box_error' : 'input_box'} value={data.fda_name} onChange={event => handleFormChange(event)} type="text" name="fda_name" placeholder="Enter FDA Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Boeing Mnemonic Code*</label>
              <input style={{ width: '250px' }} value={data.boeing_mnemonic_code} onChange={event => handleFormChange(event)} className='input_box' type="text" name="boeing_mnemonic_code" placeholder="Enter Boeing mnemonic code..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Parameter Description</label>
              <input style={{ width: '250px' }} value={data.parameter_desc} onChange={event => handleFormChange(event)} className='input_box' type="text" name="parameter_desc" placeholder="Enter Parameter Description..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Airplane Model*</label>
              <Select
                name="airplane"
                className={data.airplane == '' ? 'input_box_error' : 'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Airplane Model..."
                value={data.airplane || ''}
                options={airplaneModelOptions}
                onChange={event => handleFormChange(event)}
              >
                {airplaneModelOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>ATA Code</label>
              <Select
                name="ata_code"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select ATA Code..."
                value={data.ata_code || []}
                options={props.ataCodeOptions}
                onChange={(e) => handleFormChange(e)}
                multiple
              >
                {props.ataCodeOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>IA Sensor Name *</label>
              <input style={{ width: '250px' }} value={data.ia_sensor_name} onChange={event => handleFormChange(event)} className={data.ia_sensor_name == '' ? 'input_box_error' : 'input_box'} type="text" name="ia_sensor_name" placeholder="Enter IA Sensor Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Aggregators</label>
              <Select
                name="aggregators"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Aggregators..."
                value={data.aggregators || []}
                options={props.aggregatorOptions}
                onChange={(e) => handleFormChange(e)}
                multiple
              >
                {props.aggregatorOptions}
              </Select>
            </div>
            {
              props.customerFilter &&
              <div className='input-element'>
                <label className='input_label'>Flight Phases</label>
                <Select
                  name="flight_phases"
                  className={'input_box'}
                  style={{ width: '250px ', height: '40px' }}
                  size='small'
                  placeholder="Select Flight Phases..."
                  value={data.flight_phases || []}
                  options={props.flightPhaseOptions}
                  onChange={(e) => handleFormChange(e)}
                  multiple
                >
                  {props.flightPhaseOptions}
                </Select>
              </div>
            }
            <div className='input-element'>
              <label className='input_label'>Additional Information</label>
              <textarea style={{ width: '510px',resize: 'vertical'  }} value={data.additional_info || ''} onChange={event => handleFormChange(event)} className='input_box' type="text" name="additional_info" placeholder="Enter Additional Information..." />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={props.mutation.status === 'pending' || airplanesQuery.status === 'pending'}/>
      </div>
    )
  else
    return null
}

export default EditParameter