import React, { useState, useEffect } from 'react'
import Button from '../../Components/UI-Elements/Button'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useAuth } from '../../Components/Auth/AuthProvider'

function EditProblem(props) {
  let auth = useAuth();

  const [data, setData] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    let temp = {...props.data};
    temp.airplane_submodel_id = undefined;
    temp.airplane_id = undefined;
    temp.airplane_desc = undefined;
    temp.airplane_model = temp.airplane_model;
    temp.airplane_submodel = temp.airplane_submodel.length ? temp.airplane_submodel.split(',') : [];
    setData(temp);
  }, [])

  const getSelectedAirplanes = (event) => {
    let temp = { ...data };
    temp.airplane_model = event.target.value;
    setData(temp);
  }

  const getSelectedSubmodels = (event) => {
    let temp = {...data };
    temp.airplane_submodel = event.target.value;
    setData(temp);
  }

  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value;
    if (event.target.name == 'airplane_model')
      temp['airplane_submodel'] = []
    setData(temp);
  }

  const handleSubmit = () => {
    setSubmitLoading(true)
    submit()
  }

  async function submit() {
    console.log("submit")
    let validateError = validateForm()
    console.log('validation error : ', validateError)

    if (!validateError) {
      let submodel_ids = [];
      for(var ind in props.airplaneData[data['airplane_model']]) {
          if (data['airplane_submodel'].includes(props.airplaneData[data['airplane_model']][ind]['submodel'])) {
              submodel_ids.push(props.airplaneData[data['airplane_model']][ind]['id'])
          }
      }

      var req = {
        'problem_name': data['problem_name'],
        'customer_id': data['customer_id'],
        'ata_code': data['ata_code'].join(','),
        'airplane_id': submodel_ids,
        'version': data['version'],
        'user':auth.name
      }

      console.log('req', req)
      try {
        const res = await auth.authAxios({
          method: 'put',
          url: process.env.REACT_APP_BACKEND_API + '/problem/' + data['problem_id'],
          data: req,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        });

        console.log('response : ', res.data);
        props.modalClose()
        props.refresh()
        if(res.data.message=='success')
          props.alert({ type: 'success', text: 'Problem Updated Successfully' })

      } catch (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      } finally {
        setSubmitLoading(false)
        props.modalClose()
      }
    }
    else {
      setSubmitLoading(false)
    }


  }

  function validateForm() {
    let res = false;
    console.log("validating data...");
    if (data['problem_name'] == '' || data['customer_id'] == '' || data['airplane_model'] == '' || data['airplane_submodel'].length == 0) {
      openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
      res = true
    }
    return res;
  }

  if (props.airplaneData) {
    let airplaneKeys = Object.keys(props.airplaneData)
    var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
      return (<MenuItem key={index} value={item}>{item}</MenuItem>)
    })
  }

  if(airplaneModelOptions && airplaneModelOptions.length && data && data.airplane_model && data.airplane_model) {
    var airplaneSubmodelOptions = [];
    let submodels = data.airplane_model && props.airplaneData[data.airplane_model].length ? [...props.airplaneData[data.airplane_model]] : [];
    if (submodels.length) {
        submodels.map((item, _index) => {
            airplaneSubmodelOptions.push(<MenuItem key={item.id} value={item.submodel}>{item.submodel}</MenuItem>)
        })
    }
  }

  if (data)
    return (
      <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
              <label className='input_label'>Problem Name</label>
              <input style={{ width: '250px' }} value={data.problem_name} onChange={event => handleFormChange(event)} className={data.paroblem_name == '' ? 'input_box_error' : 'input_box'} type="text" name="problem_name" placeholder="Enter Problem Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Customer Name</label>
              <select style={{ width: '250px' }} className={data.customer_id == '' ? 'input_box_error' : 'input_box'} value={data.customer_id} onChange={event => handleFormChange(event)} name="customer_id">
                <option value="" disabled>Select Customer...</option>
                {props.customerOptions}
              </select></div>
            <div className='input-element'>
              <label className='input_label'>Airplane Model*</label>
              <Select
                name="airplane_model"
                className={data.airplane_model == '' ? 'input_box_error' : 'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Airplane Model..."
                value={data.airplane_model}
                options={airplaneModelOptions}
                onChange={(e) => getSelectedAirplanes(e)}
              >
                {airplaneModelOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>Airplane Sub-Model*</label>
                <Select
                  name="airplane_submodel"
                  className={data.airplane_submodel == '' ? 'input_box_error' : 'input_box'}
                  style={{ width: '250px ', height: '40px' }}
                  size='small'
                  placeholder="Select Airplane Model..."
                  value={data.airplane_submodel}
                  options={airplaneSubmodelOptions}
                  onChange={(e) => getSelectedSubmodels(e)}
                  multiple
                >
                  {airplaneSubmodelOptions}
                </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>ATA Code</label>
                <Select
                  name="ata_code"
                  className={'input_box'}
                  style={{ width: '250px ', height: '40px' }}
                  size='small'
                  placeholder="Select ATA Code..."
                  value={data.ata_code || ''}
                  options={props.ataCodeOptions}
                  onChange={(e) => handleFormChange(e)}
                  multiple
                >
                  {props.ataCodeOptions}
                </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>Version</label>
              <input style={{ width: '70px' }} value={data.version} onChange={event => handleFormChange(event)} className={data.version == '' ? 'input_box_error' : 'input_box'} type="text" name="version" disabled />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>

      </div>
    )
  else
    return null
}

export default EditProblem