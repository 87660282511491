import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useTable, useSortBy, useGlobalFilter, useFilters, useAsyncDebounce, usePagination } from 'react-table'

import { Divider, Skeleton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import '../../Styles/Components/Table.css'


// React Table Documentation : https://react-table-v7.tanstack.com/docs/quick-start



// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className='table-search'>
            <div>
                Search
            </div>
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

function DefaultColumnFilter({
    column: { filterValue, setFilter },
}) {
    return (
        <div className='table-header-filter'>
            <FilterAltIcon />
            <input
                value={filterValue || ''}
                onChange={e => {
                    let value = e.target.value || undefined;
                    setFilter(value); // Set undefined to remove the filter entirely
                }}
                placeholder={`Contains...`}
                style={{ border: '1px' }}
            />
        </div>
    )
}

function multiSelectFilter(rows, columnIds, filterValue) {
    return filterValue.length === 0 ? rows : rows.filter((row) => {
        let value = row.values[columnIds];
        if(Array.isArray(value)){
            value = value.toString();
        } else if (Object(value) === value) {
            value = JSON.stringify(value);
        }
        // Process keywords. Filter values are split on ',', ' ' and '_' characters. Words are treacted as keywords
        const re = /\s{2,}/
        let temp = filterValue.split(',').map((filterKW) => {
            return(filterKW.trim().replace(re, ' ').split(' '))
        }).flat().filter((filterKW) => filterKW.length > 0);

        temp = temp.map((filter) => {
            return filter && value && value.toString().toLowerCase().includes(filter.toString().toLowerCase());
        });
        return temp.reduce((acc, value) => acc && value, true);
    });
}
/*

props:

header - colums headers
data - table data
title - table title
pageSize - no. of rows to show by default

*/

function Table(props) {

    const [data, setData] = useState(null)
    const [columns, setColumns] = useState([])

    const defaultColumn = useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),[])
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        //pagination
        // rows, //uncomment if not using pagination
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        //global search
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        defaultColumn,
        //pagination
        initialState: { 
            pageIndex: props.pageIndex ?? 0, 
            pageSize: props.pageSize > 0 ? props.pageSize : 10,
        }
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    useEffect(() => {
        setData([]);
        setData(props.data);
        let header = [...props.header];
        header.map((h) => h.filter = multiSelectFilter);
        setColumns(header)
        setPageSize(props.pageSize)
    }, [props])

    if (columns && data) {
        const filterableCols = columns.filter((c) => c.canFilter).map((c) => c.Header);
        return (
            <div className='table-container'>
                <div className='table-top'>
                    <p className='table-title'>{props.title}</p>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                {
                    props.customContent &&
                    <div>
                        {props.customContent}
                    </div>
                }
                <div className='table-main'>
                    <table {...getTableProps()} className='table-all'>
                        <thead style={{ backgroundColor: '#F5F5FA' }}>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(column => (
                                        <th className='table-header' >
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div className='table-header-title'>{column.render('Header')}</div>
                                                <p>
                                                    {
                                                        //sorting icons
                                                        column.isSorted ?
                                                            column.isSortedDesc ?
                                                                <span className="material-icons">keyboard_arrow_down</span>
                                                                :
                                                                <span className="material-icons">keyboard_arrow_up</span>
                                                            : ''
                                                    }
                                                </p>
                                            </div>
                                            {
                                                filterableCols.includes(column.Header) &&
                                                <>
                                                    <Divider variant='fullWidth' />
                                                    { column.render('Filter') }
                                                </>
                                            }
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {/* {rows.map(row => { */
                                page.map((row) => {
                                    prepareRow(row)
                                    return (
                                        <tr   {...row.getRowProps()} >
                                            {row.cells.map(cell => {
                                                return (<td {...cell.getCellProps()} className='table-data'> {cell.render('Cell')} </td>)
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>


                {/* Pagination UI */}

                {
                    page.length > 0 ?
                        <div className="table-pagination">
                            <div>
                                Rows per page{' '}
                                <select
                                    className='table-input'
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}

                                >

                                    {[10, 50, 100, 500, 1000].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}

                                    {
                                        props.pageSize != null ?
                                            <option value={props.pageSize}>
                                                all
                                            </option>
                                            : ''
                                    }
                                </select>

                            </div>
                            <div>
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    Go to page{' '}
                                    <input
                                        className='table-input'
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>
                            </div>


                            <div className='table-navigation'>
                                <div onClick={() => gotoPage(0)} className={canPreviousPage ? '' : 'table-nav-btn-disable'} >
                                    <span className="material-icons">first_page</span>
                                </div>{' '}
                                <div onClick={() => previousPage()} className={canPreviousPage ? '' : 'table-nav-btn-disable'} >
                                    <span className="material-icons">chevron_left</span>
                                </div>{' '}
                                <div onClick={() => nextPage()} className={canNextPage ? '' : 'table-nav-btn-disable'}>
                                    <span className="material-icons">chevron_right</span>
                                </div>{' '}
                                <div onClick={() => gotoPage(pageCount - 1)} className={canNextPage ? '' : 'table-nav-btn-disable'}>
                                    <span className="material-icons">last_page</span>
                                </div>{' '}
                            </div>
                        </div>

                        :
                        ''
                }

            </div>

        )
    }
    else {
        return (
            <Skeleton variant="rectangular" width='100%' height='200px' />
        )
    }
}

export default Table